<template>
  <div :class="{ 'permission-table': true, 'permission-table--fullscreen': fullScreen }" >
    <div class="permission-table__header">
      {{ headerText }}
      <Checkbox
        id="fullScreen"
        v-model="fullScreen"
        :label="$t('beUser.fullscreen')"
      />
    </div>
    <div class="permission-table__body">
      <Preloader v-if="callingApiCount > 0" />
      <DataTable
        v-else
        :data="itemsView"
        :pagination="false"
        :expandable="true"
        :config="dataTableConfig"
        :disabled="disabled"
        :table-height="fullScreen ? 'calc(100vh - 100px)' : '500px'"
        :show-only-selected-checkboxes="disabled"
        :disabled-checkbox-tooltip="$t('beUser.disabledPermissionTooltip')"
        @boolean-field-checked="capabilityChecked"
        @boolean-field-checked-all="capabilityCheckedAll"
        @expand-toggle="expandToggle"
      >
        <template #header-id>
          <Input
            v-model="filter"
            class="permission-table__filter"
            no-label
            magnifier
            :placeholder="$t('beUser.searchPermissionGroups')"
            @input="filterItems"
          />
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { set, get } from 'lodash'
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import DataTable from '@/components/shared/DataTable'
import {
  FIELD_EXPANDED,
  FIELD_PARENT,
  FIELD_TYPE_EDITABLE_BOOLEAN,
  FIELD_TYPE_TEXT,
  PERMISSION_SET
} from '@/model/ValueObject/DataTableFields'
import Input from '@/components/form/inputs/Input'
import Checkbox from '@/components/form/Checkbox'

const DEFAULT_EXPANDED = true

export default {
  name: 'BeUserPermissionTable',
  props: {
    selectedGroups: {
      type: Array,
      default: () => []
    },
    selectedCapabilities: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      brands: [],
      capabilities: [],
      items: [],
      itemsView: [],
      filter: '',
      fullScreen: false,
      headerText: this.$t('beUser.userAuthorization'),
      currentSelectedCapabilities: [],
      selectedDisabledCapabilities: []
    }
  },
  async mounted () {
    try {
      this.loadingOn()
      await this.loadSelectedGroups()

      // Prepare array of promises
      const promises = []
      promises.push(this.$store.dispatch('capability/fetch'))
      promises.push(this.$store.dispatch('brand/fetch'))
      // Process all promises and wait for all to complete
      await Promise.all(promises)

      this.capabilities = this.$store.getters['capability/list']
      this.brands = this.$store.getters['brand/list']
      this.currentSelectedCapabilities = this.selectedCapabilities

      this.transferDataForTable()
      this.loadingOff()
    } catch (error) {
      console.error(error)
      this.loadingOff()
    }
  },
  computed: {
    ...mapState([
      'callingApiCount'
    ]),
    selectedDisabledCapabilitiesMap () {
      return this.transferToSelectedDisabledCapabilitiesMap(this.selectedDisabledCapabilities)
    },
    selectedCapabilitiesMap () {
      return this.transferToSelectedCapabilitiesMap(this.currentSelectedCapabilities)
    },
    dataTableConfig () {
      const brandFields = {}
      const brandFieldTypes = {}
      this.brands.forEach(brand => {
        brandFields[`${brand.code}.${PERMISSION_SET}`] = brand.code
        brandFieldTypes[`${brand.code}.${PERMISSION_SET}`] = FIELD_TYPE_EDITABLE_BOOLEAN
      })
      return {
        fields: {
          id: '',
          'capability.code': '',
          [PERMISSION_SET]: '',
          ...brandFields
        },
        fieldTypes: {
          id: FIELD_TYPE_TEXT,
          'capability.code': FIELD_TYPE_TEXT,
          [PERMISSION_SET]: FIELD_TYPE_EDITABLE_BOOLEAN,
          ...brandFieldTypes
        }
      }
    }
  },
  components: {
    Checkbox,
    Preloader,
    DataTable,
    Input
  },
  watch: {
    async selectedGroups () {
      await this.loadSelectedGroups()
    }
  },
  methods: {
    loadingOn () {
      this.$store.commit('LOADING_ADD')
    },
    loadingOff () {
      this.$nextTick(() => this.$store.commit('LOADING_REMOVE'))
    },
    async loadSelectedGroups () {
      try {
        this.loadingOn()
        this.currentSelectedCapabilities = this.getCapabilitiesForSave()
        this.selectedDisabledCapabilities = await this.fetchGroupsCapabilities()
        this.transferDataForTable()
        this.loadingOff()
      } catch (error) {
        console.error(error)
        this.loadingOff()
      }
    },
    transferDataForTable () {
      this.loadingOn()
      this.items = this.getTransferredDataForTable(this.capabilities, this.brands)

      if (this.filter) {
        this.filterItems() // this loads all the items into 'this.itemsView' with the currently set filter
        this.loadingOff()
      } else {
        this.itemsView = this.items
        this.loadingOff()
      }
    },
    expandToggle (toggledItem) {
      this.itemsView.forEach(item => {
        if (item.module.code === toggledItem.module.code) {
          item[FIELD_EXPANDED] = !item[FIELD_EXPANDED]
        }
      })
    },
    capabilityChecked (event) {
      const checkedItem = this.itemsView.find(item => {
        return item[FIELD_PARENT] === event.item[FIELD_PARENT] &&
          item.capability.capabilityId === event.item.capability.capabilityId
      })
      const path = `${event.fieldName}.value`
      set(checkedItem, path, event.checked)
    },
    capabilityCheckedAll (event) {
      this.itemsView.forEach(item => {
        const path = `${event.fieldName}.value`
        if (item[FIELD_PARENT] && typeof get(item, path) === 'boolean') {
          set(item, path, event.checked)
        }
      })
    },
    async fetchGroupsCapabilities () {
      // Prepare array of promises
      const promises = this.selectedGroups.map(group => this.$store.dispatch('group/fetchOne', group.groupId))
      // Process all promises and wait for all to complete
      const results = await Promise.all(promises)
      return results.flat(1)
    },
    /**
     * Returns a map of { capability: { brand: { disabled: boolean, groupId: string } } }
     */
    transferToSelectedDisabledCapabilitiesMap (groupsOfDisabledCapabilities) {
      const resultMap = {}
      for (const group of groupsOfDisabledCapabilities) {
        for (const capability of group.capabilities) {
          if (!resultMap[capability.capabilityId]) {
            resultMap[capability.capabilityId] = {}
          }
          const groupNames = resultMap[capability.capabilityId][capability.brandId ?? PERMISSION_SET]?.groupName
          const groupName = groupNames ? `${groupNames}, ${group.name}` : group.name
          resultMap[capability.capabilityId][capability.brandId ?? PERMISSION_SET] = { disabled: true, groupName }
        }
      }
      return resultMap
    },
    /**
     * Returns a map of { capability: { brand: boolean } }
     */
    transferToSelectedCapabilitiesMap (capabilities) {
      return capabilities.reduce((acc, capability) => {
        if (!acc[capability.capabilityId]) {
          acc[capability.capabilityId] = {}
        }
        acc[capability.capabilityId][capability.brandId ?? PERMISSION_SET] = true
        return acc
      }, {})
    },
    /**
     * Returns a map of { module: capabilities[] }
     */
    transferToModulesMap (capabilities) {
      const modules = {}
      capabilities.forEach(capability => {
        if (!modules[capability.module.code]) {
          modules[capability.module.code] = { module: capability.module, capabilities: [] }
        }
        modules[capability.module.code].capabilities.push(capability)
      })
      return modules
    },
    getDisabledReason (groupName) {
      return this.$t('beUser.disabledPermissionTooltip', { groupName })
    },
    /**
     * Returns a map of { brandCode: boolean }
     */
    getTransferredBrands (capability, brands) {
      if (!capability.brandRequired) {
        const groupCapability = this.selectedDisabledCapabilitiesMap[capability.capabilityId]?.[PERMISSION_SET]
        if (groupCapability?.disabled) {
          return {
            [PERMISSION_SET]: {
              value: true,
              disabled: true,
              checkboxTooltip: this.getDisabledReason(groupCapability.groupName)
            }
          }
        }
        const permissionSet = this.selectedCapabilitiesMap[capability.capabilityId]?.[PERMISSION_SET]
        return {
          [PERMISSION_SET]: { value: Boolean(permissionSet), disabled: false }
        }
      }

      return brands.reduce((acc, brand) => {
        const groupCapability = this.selectedDisabledCapabilitiesMap[capability.capabilityId]?.[brand.brandId]
        if (groupCapability?.disabled) {
          acc[`${brand.code}`] = {
            ...brand,
            [PERMISSION_SET]: {
              value: true,
              disabled: true,
              checkboxTooltip: this.getDisabledReason(groupCapability.groupName)
            }
          }
          return acc
        }
        const permissionSet = this.selectedCapabilitiesMap[capability.capabilityId]?.[brand.brandId]
        acc[`${brand.code}`] = { ...brand, [PERMISSION_SET]: { value: Boolean(permissionSet), disabled: false } }
        return acc
      }, {})
    },
    /**
     * Returns an array of capabilities grouped by modules.
     */
    getTransferredDataForTable (capabilities, brands) {
      const result = []
      const moduleCapabilityMap = this.transferToModulesMap(capabilities)
      Object.keys(moduleCapabilityMap).forEach(moduleCode => {
        const module = moduleCapabilityMap[moduleCode].module
        result.push({
          id: module.name,
          module,
          capability: { code: '' },
          [FIELD_EXPANDED]: DEFAULT_EXPANDED
        })
        moduleCapabilityMap[moduleCode].capabilities.forEach(capability => {
          const row = {
            id: undefined,
            [FIELD_PARENT]: module.code,
            [FIELD_EXPANDED]: DEFAULT_EXPANDED,
            module,
            capability: capability,
            key: capability.code, // as 'id' is undefined we need a 'key' for vue-rerender
            ...this.getTransferredBrands(capability, brands)
          }
          result.push(row)
        })
      })
      return result
    },
    getCapabilitiesForSave () {
      const result = []
      this.items.forEach(item => {
        if (item[FIELD_PARENT]) {
          Object.keys(item).forEach(key => {
            if (key !== FIELD_EXPANDED) {
              if (key === PERMISSION_SET && item[PERMISSION_SET]?.value === true) {
                if (!item[PERMISSION_SET]?.disabled) {
                  // ignore disabled permissions as
                  // they will be saved as a part of permissionGroup set to the user
                  result.push({
                    capabilityId: item.capability.capabilityId,
                    brandId: null // first checkbox column in the table, so no brand
                  })
                }
              } else if (item[key]?.[PERMISSION_SET]?.value === true &&
                !item[key]?.[PERMISSION_SET]?.disabled) {
                result.push({
                  capabilityId: item.capability.capabilityId,
                  brandId: item[key].brandId
                })
              }
            }
          })
        }
      })
      return result
    },
    getCapabilityModulesForSave () {
      const getBrandList = item => {
        const result = []
        for (const brandCode of Object.keys(item)) {
          if (brandCode !== FIELD_EXPANDED) {
            if (brandCode === PERMISSION_SET && item[PERMISSION_SET]?.value === true) {
              if (!item[PERMISSION_SET]?.disabled) {
                // ignore disabled permissions as
                // they will be saved as a part of permissionGroup set to the user
                return undefined
              }
            } else if (item[brandCode]?.[PERMISSION_SET]?.value === true &&
              !item[brandCode]?.[PERMISSION_SET]?.disabled) {
              result.push(brandCode)
            }
          }
        }
        return result
      }

      const result = {}
      this.items.forEach(item => {
        if (item[FIELD_PARENT]) {
          const brandList = getBrandList(item)
          if (brandList === undefined) {
            if (!result[item[FIELD_PARENT]]) {
              result[item[FIELD_PARENT]] = []
            }
            result[item[FIELD_PARENT]].push(item.capability.code)
          } else if (brandList.length > 0) {
            if (!result[item[FIELD_PARENT]]) {
              result[item[FIELD_PARENT]] = {}
            }
            result[item[FIELD_PARENT]][item.capability.code] = brandList
          }
        }
      })
      return result
    },
    filterItems () {
      this.itemsView = this.items.filter(item => item.module?.name?.toLowerCase().startsWith(this.filter.toLowerCase()) ||
        item.module?.name?.toLowerCase().startsWith(this.filter.toLowerCase())
      )
    }
  }
}
</script>

<style scoped lang="scss">
  .permission-table {
    border: rem(1px) solid rgba(0,0,0,0.125);
    border-radius: rem(4px);
    background-color: #ffffff;
    box-shadow: 0rem rem(5px) rem(20px) rgba(0,0,0,0.05);
    &--fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 12;
      width: 100%;
    }
    &__header {
      background: #ffffff;
      padding: rem(5px) rem(20px) rem(5px) rem(15px);
      display: flex;
      justify-content: space-between;
    }
    &__body {
      padding: 1.25rem;
    }
    &__filter {
      width: rem(200px);
    }
}
</style>
